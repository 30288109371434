import React, { useState } from 'react';
import VideoModal from './VideoModal';

const VimeoEmbed = ({ videoId, width = 720, height = 480 }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClick = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <div className="vimeo-wrapper" onClick={handleClick}>
        <iframe
          src={`https://player.vimeo.com/video/${videoId}?autoplay=1&loop=1&background=1&muted=1`}
          width={width}
          height={height}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          title="Vimeo video player"
        ></iframe>
      </div>
      {isModalOpen && 1 < 0 && (
        <VideoModal videoId={videoId} onClose={() => setIsModalOpen(false)} />
      )}
    </>
  );
};

export default VimeoEmbed;