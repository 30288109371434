import "./MiloDom.css";
import { FaHamburger } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { useState } from "react";
import { useEffect } from "react";
import "../../AboutMe.css";
import BarLoader from "react-spinners/BarLoader";
import VimeoEmbed from "../VideoEmbed/VideoEmbed";
import { INCLUDE_FRANKEN } from "../../constants";

export default function MiloDom(props) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [contactOpen, setContactOpen] = useState(false);
  const [emailClick, setEmailClick] = useState(false);
  const [localHover, setLocalHover] = useState(false);

  useEffect(() => {
    props.handleHoverDom(localHover);
  }, [localHover]);

  function handleEmailClick() {
    navigator.clipboard.writeText("milo.arnerich@gmail.com");
    setEmailClick(true);
    setTimeout(function () {
      setEmailClick(false);
    }, 2000);
  }

  function handleMenuClick() {
    setMenuOpen(!menuOpen);
    //props.setMenuActive(menuOpen);
  }

  function handleAboutOpen() {
    setContactOpen(true);
    setIsContact(false);
    setMenuOpen(false);
    props.setAboutActive(true);
  }

  function handleContactOpen() {
    setContactOpen(true);
    setIsContact(true);
    setMenuOpen(false);
    props.setAboutActive(true);
  }

  function handleTextClose() {
    setContactOpen(false);

    props.setAboutActive(false);
  }

  function handleTouchMove(event) {
    event.stopPropogation();
  }

  function handlePointerMove(event) {
    event.preventDefault();
    let x = (event.clientX / window.innerWidth) * 2.0 - 1;
    let y = (event.clientY / window.innerHeight) * -2.0 + 1;
    props.setPointer([x, y]);
  }

  function handlePointerStartEnd(event) {
    let x = (event.clientX / window.innerWidth) * 2.0 - 1;
    let y = (event.clientY / window.innerHeight) * -2.0 + 1;
    props.setPointer([x, y]);
  }

  const [isContact, setIsContact] = useState(false);

  return (
    <div
      className={
        props.doneLoading === 2
          ? "dom-elements-clear"
          : props.doneLoading === 0
          ? "dom-elements-black"
          : "dom-elements-white"
      }
      onMouseMove={handlePointerMove}
      onTouchMove={handleTouchMove}
      onTouchStart={handleTouchMove}
      onTouchEnd={handleTouchMove}
    >
      {!props.doneLoading ? ( //!props.doneLoading
        <div className="loading-canvas">
          <BarLoader
            color={"#ffffff"}
            loading={props.doneLoading === 0}
            className="loading-bar"
          />
          <p className="loading-text">Loading...</p>
        </div>
      ) : (
        ""
      )}

      <div className="grid-container">
        <div className="nav-menu">
          <div className={!contactOpen ? "show-burger" : "burger-hidden"}>
            <div className="burger" onClick={handleMenuClick}>
              {menuOpen ? (
                <RxCross2 size={40} className="symbol" />
              ) : (
                <FaHamburger size={40} className="symbol" />
              )}
            </div>

            <p
              className={menuOpen ? "menu-active" : "menu-inactive"}
              onClick={handleAboutOpen}
            >
              About
            </p>
            {/*
                            <p className={menuOpen ? 'menu-active' : 'menu-inactive'}>Shaders</p>
                            <p className={menuOpen ? 'menu-active' : 'menu-inactive'}>Reel</p>
                            */}
            <p
              className={menuOpen ? "menu-active" : "menu-inactive"}
              onClick={handleContactOpen}
            >
              Contact
            </p>
          </div>
        </div>

        <div
          className={!contactOpen ? "contact-card-closed" : "contact-card-open"}
        >
          <div className="contact-header">
            <RxCross2 size={30} onClick={handleTextClose} className="symbol" />
          </div>

          {isContact ? (
            <div className="email-contact">
              <h4>email me at:</h4>
              <h4 className="my-email" onClick={handleEmailClick}>
                milo.arnerich@gmail.com
              </h4>
              <h4 className={emailClick ? "copied-show" : "copied-hide"}>
                copied to clipboard!
              </h4>
            </div>
          ) : (
            <div>
              <AboutMe
                handleEmailClick={handleEmailClick}
                emailClick={emailClick}
              />
            </div>
          )}
        </div>

        <div className="blank2"></div>
        <div className="blank3"></div>

        {!contactOpen ? (
          <div
            className="name-title"
            style={{ position: "absolute", bottom: "5rem", left: "20px" }}
          >
            <h3>Milo Arnerich</h3>
            <h3>Fullstack Developer/Technical Artist</h3>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

const AboutMe = ({ handleEmailClick, emailClick }) => {
  return (
    <div className="about-me">
      <h1>About Me</h1>
      <p>
        I'm a fullstack developer and technical artist with a passion for
        creating immersive 3D web experiences. My unique blend of skills allows
        me to bridge the gap between cutting-edge web technologies and
        captivating 3D visuals.
      </p>

      <h2>Professional Experience</h2>
      <p>
        My journey in tech has been diverse and exciting. I've had the
        opportunity to work on some amazing projects, including:
      </p>
      <ul>
        <li>
            <h2>VOLT AI</h2>
          <p>Contributed to the enhancement of an interactive 3D map for a tech
          security company. Focused on improving the map's aesthetic appeal and
          developed the initial prototype for projecting AI-generated
          camera/screen coordinates onto the 3D environment. This visualization
          tool displays real-time positions of individuals based on AI
          algorithms.</p>
          <div class="video-container">
            <VimeoEmbed videoId="1021137859" />
            <VimeoEmbed videoId="1021138571" />
          </div>
        </li>
        {INCLUDE_FRANKEN && <li>
        <h2>FrankenScans</h2>
          Founding and running{" "}
          <a href="https://frankenscans.co">FrankenScans</a>, a 3D asset store
          that provides high-quality resources to fellow creators.
        </li>}
      </ul>

      <h2>Technical Expertise</h2>
      <div className="expertise-grid">
        <div>
          <h3>3D Specialties</h3>
          <ul>
            <li>Custom shader development</li>
            <li>Procedural/Data-driven geometry and animations</li>
            <li>Creating interactive 3D web experiences</li>
          </ul>
        </div>
        <div>
          <h3>Fullstack Development</h3>
          <ul>
            <li>React and React Three Fiber for 3D web applications</li>
            <li>
              Next.js for building performant and scalable web applications
            </li>
            <li>
              Seamless integration of 3D content with modern web technologies
            </li>
          </ul>
        </div>
      </div>

      <br/><br/><br/><br/><br/><br/>
      <p>Whether it's crafting intricate procedural animations,
        optimizing performance for complex 3D scenes, or developing intuitive
        user interfaces for 3D interactions, I'm always eager to take on new
        challenges.
      </p>
      <p>
        My goal is to continue innovating at the intersection of web development
        and 3D graphics, creating experiences that are not just visually
        stunning, but also functional and accessible to users across the globe.
      </p>
      <p className="closing">
        I am currently open to work, you can contact me at:
      </p>
      <h4 className="my-email" onClick={handleEmailClick}>
        milo.arnerich@gmail.com
      </h4>
      <h4 className={emailClick ? "copied-show" : "copied-hide"}>
        copied to clipboard!
        <br />
        <br />{" "}
      </h4>
      <h4 className="copied-hide">blah blah blah hidden</h4>
    </div>
  );
};
